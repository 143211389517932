<template>
  <b-container fluid>
    <b-overlay :show="saveLoading">
        <b-col md="12" lg="12" sm="12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(saveFormData)" >
              <div>
                <b-table-simple bordered>
                    <b-tr>
                      <b-th style="width: 20%;">{{ $t('service_name.service_name') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ getServiceName(service_id) }}</b-td>
                      <b-th style="width: 20%;">{{ $t('li_step.application_id') }}</b-th>
                      <b-td style="width: 30%;text-align: left;">{{ application_id }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('externalLrcpn.certificate_change_details') }}</b-th>
                      <b-td colspan="3">{{ note  }}</b-td>
                    </b-tr>
                </b-table-simple>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="Messrs (En)" vid="company_na__1531" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_na__1531"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.messrs') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="company_na__1531"
                              v-model="formData.company_na__1531"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Messrs (Bn)" vid="company_na___9403" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_na___9403"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('li_step.messrs') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="company_na___9403"
                              v-model="formData.company_na___9403"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Name of Proprietor (En)" vid="name_of_ap_____8058" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="name_of_ap_____8058"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.name_of_proprietor') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="name_of_ap_____8058"
                              v-model="formData.name_of_ap_____8058"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Name of Proprietor (Bn)" vid="name_of_ap_____7351" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="name_of_ap_____7351"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('li_step.name_of_proprietor') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="name_of_ap_____7351"
                              v-model="formData.name_of_ap_____7351"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Mobile No" vid="mobile_no____5100" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="mobile_no____5100"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('irri_pump_main.mobile_no') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="mobile_no____5100"
                              v-model="formData.mobile_no____5100"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Division" vid="division_8106" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division_8106"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.division_8106"
                          :options="divisionList"
                          id="division_8106"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="District" vid="district_3887" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="district_3887"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.district_3887"
                          :options="districtList"
                          id="district_3887"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Upazila" vid="upazila_9408" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="upazila_9408"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.upazila')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="formData.upazila_9408"
                          :options="upazilaList"
                          id="upazila_9408"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Address (En)" vid="company_address_3161" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_address_3161"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.address') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              rows="2"
                              id="company_address_3161"
                              v-model="formData.company_address_3161"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="Address (Bn)" vid="company_address_(bn)" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_address_(bn)"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                              {{ $t('globalTrans.address') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              rows="2"
                              id="company_address_(bn)"
                              v-model="formData['company_address_(bn)']"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 text-right">
                  <b-button type="submit" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('certificate-change')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { changeCertificateDataList, certificateChangeStore } from '../../api/routes'
export default {
  props: ['item'],
  components: {
  },
  data () {
    return {
      saveLoading: false,
      loadHistory: false,
      application_id: this.item.application_id,
      service_id: this.item.service_id,
      note: this.item.note,
      formData: {
        cc_request_id: this.item.id
      },
      districtList: [],
      upazilaList: [],
      history: []
    }
  },
  created () {
    this.showCertificateData()
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.division_8106': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'formData.district_3887': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    getServiceName (id) {
      const serviceType = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOrganizationName (id) {
      const serviceType = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOfficeTypeName (id) {
      const officeType = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return officeType !== undefined ? officeType.text_bn : ''
      } else {
        return officeType !== undefined ? officeType.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    async showCertificateData () {
      this.saveLoading = true
      const params = {
        application_id: this.item.application_id,
        service_id: this.item.service_id
      }
      await RestApi.getData(licenseRegistrationServiceBaseUrl, changeCertificateDataList, params).then(response => {
        if (response.success) {
          this.formData = response.data
        }
      })
      this.saveLoading = false
    },
    async saveFormData () {
      let result = null
      this.saveLoading = true
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, certificateChangeStore, this.formData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.saveLoading = false
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('certificate-change')
      } else {
        this.saveLoading = false
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        districtList = districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    }
  }
}
</script>
<style>
  .b-table-simple {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .b-tr:hover {
    background-color: #f5f5f5;
  }

  .b-th, .b-td {
    border: 1px solid #ddd;
    padding: 7px;
    background-color: #552323;
  }

  .b-tr:nth-child(even) {
    background-color: #643232;
  }
</style>
