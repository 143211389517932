<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalLrcpn.certificate_change_request') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Application Id" vid="application_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="application_id"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('li_step.application_id')}}
                    </template>
                    <b-form-input
                      id="application_id"
                      v-model="search.application_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('externalLrcpn.certificate_change_request') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ getOrgName(data.item.org_id) }}
                    </template>
                    <template v-slot:cell(service_id)="data">
                      {{ getServiceName(data.item.service_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.changed ? $t('globalTrans.changed') : $t('globalTrans.pending') }}
                    </template>
                    <template v-slot:cell(type)="data">
                      <slot v-if="data.item.type === 1">{{ $t('globalTrans.new') }}</slot>
                      <slot v-if="data.item.type === 2">{{ $t('globalTrans.renew') }}</slot>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-if="!data.item.changed" class="btn btn-success m-1 btn-sm" :title="$t('externalLrcpn.certificate_change_request')" @click="certificateChangeData(data.item)"><i class="fa fa-user-circle" aria-hidden="true"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="certificate-change" size="lg" :title="$t('externalLrcpn.certificate_change_request')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <CertificateChange :item="certificateChangeRequest"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import CertificateChange from './CertificateChange'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { changeCertificateRequestList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ValidationObserver,
    ValidationProvider,
    CertificateChange
  },
  data () {
    return {
      search: {
        application_id: ''
      },
      certificateChangeRequest: {}
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('externalLrcpn.app_id'), class: 'text-left' },
        { label: this.$t('globalTrans.org_name'), class: 'text-left' },
        { label: this.$t('service_name.service_name'), class: 'text-left' },
        { label: this.$t('externalLrcpn.type'), class: 'text-left' },
        { label: this.$t('testing_and_tag_issue.created_at'), class: 'text-left' },
        { label: this.$t('externalLrcpn.expired_date'), class: 'text-left' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'organization' },
          { key: 'service_id' },
          { key: 'type' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'organization' },
          { key: 'service_id' },
          { key: 'type' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, changeCertificateRequestList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    },
    getServiceName (id) {
      const serviceType = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    certificateChangeData (data) {
      this.certificateChangeRequest = Object.assign(
      {
        id: data.id,
        application_id: data.application_id,
        service_id: data.service_id,
        application_type: data.type,
        note: data.note
      })
      this.$bvModal.show('certificate-change')
    }
  }
}
</script>
